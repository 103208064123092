import { Group, Text } from '@mantine/core';

import { CottageTooltip } from '@/components/shared/default';

export type AttributeProps = {
  icon: JSX.Element;
  text: string;
  tooltipText?: string;
};

export const Attribute = ({ icon, text, tooltipText }: AttributeProps) => {
  return (
    <Group noWrap spacing="xxs">
      {icon}
      <CottageTooltip label={tooltipText} w={200}>
        <Text c="blue.9" style={{ cursor: 'default' }}>
          {text}
        </Text>
      </CottageTooltip>
    </Group>
  );
};
