import { Badge, BadgeProps, useMantineTheme } from '@mantine/core';
import { CompanyAvailability } from '@prisma/client';

type AvailabilityBadgeProps = {
  availability: CompanyAvailability;
} & BadgeProps;

export const AvailabilityBadge = ({
  availability,
  ...otherProps
}: AvailabilityBadgeProps) => {
  const theme = useMantineTheme();

  const colorAndLabel = {
    [CompanyAvailability.AVAILABLE]: {
      backgroundColor: theme.colors.green[7],
      color: 'green.7',
      label: 'OPEN TO WORK',
    },
    [CompanyAvailability.UNAVAILABLE]: {
      backgroundColor: theme.colors.orange[9],
      color: 'orange.9',
      label: 'CLOSED TO WORK',
    },
  };

  const { backgroundColor, color, label } = colorAndLabel[availability];

  return (
    <Badge
      color={color}
      leftSection={
        <div
          style={{
            width: 4,
            height: 4,
            backgroundColor,
            borderRadius: '50%',
          }}
        />
      }
      {...otherProps}
    >
      {label}
    </Badge>
  );
};
