import { createStyles, Radio, RadioProps } from '@mantine/core';

type CottageRadioCardStylesOptions = {
  onHoverBackgroundColor?: string;
  onSelectedBackgroundColor?: string;
  radioVerticalAlignment?: string;
};

const useStyles = createStyles(
  (
    theme,
    {
      onHoverBackgroundColor,
      onSelectedBackgroundColor,
      radioVerticalAlignment,
    }: CottageRadioCardStylesOptions
  ) => ({
    radio: {
      border: `1px solid ${theme.colors.gray[4]}`,
      borderRadius: '4px',
      '&:hover': {
        backgroundColor: onHoverBackgroundColor ?? theme.colors.blue[0],
      },
      '&[data-checked="true"]': {
        backgroundColor: onSelectedBackgroundColor ?? theme.colors.blue[0],
        borderColor: theme.colors.gray[9],
      },
      '.mantine-Radio-label': {
        cursor: 'pointer',
        padding: '24px',
        paddingLeft: '12px',
      },
      '.mantine-Radio-inner': {
        alignSelf: radioVerticalAlignment ?? 'center',
        WebkitAlignSelf: radioVerticalAlignment ?? 'center',
        marginLeft: '12px',
        paddingBottom: '24px',
        paddingTop: '24px',
      },
      '.mantine-Radio-radio': {
        cursor: 'pointer',
      },
    },
  })
);

type CottageRadioCardProps = CottageRadioCardStylesOptions & RadioProps;

export const CottageRadioCard = ({
  onHoverBackgroundColor,
  onSelectedBackgroundColor,
  radioVerticalAlignment,
  ...otherProps
}: CottageRadioCardProps) => {
  const { classes } = useStyles({
    onHoverBackgroundColor,
    onSelectedBackgroundColor,
    radioVerticalAlignment,
  });

  return <Radio className={classes.radio} {...otherProps} />;
};
