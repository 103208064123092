import { useMantineTheme } from '@mantine/core';
import { CompanyAvailability } from '@prisma/client';

type AvailabilityDotProps = {
  availability: CompanyAvailability;
};

export const AvailabilityDot = ({ availability }: AvailabilityDotProps) => {
  const theme = useMantineTheme();

  const backgroundColorMap = {
    [CompanyAvailability.AVAILABLE]: theme.colors.green[7],
    [CompanyAvailability.UNAVAILABLE]: theme.colors.orange[8],
  };

  return (
    <div
      style={{
        position: 'absolute',
        right: -1,
        top: -1,
        border: `2px solid ${theme.colors.blue[9]}`,
        borderRadius: '50%',
        width: '12px',
        height: '12px',
        backgroundColor: backgroundColorMap[availability],
      }}
    />
  );
};
