import { Image, MantineNumberSize, ThemeIcon } from '@mantine/core';

type SuperProLaurelProps = {
  size: MantineNumberSize;
};

export const SuperProLaurel = ({ size }: SuperProLaurelProps) => {
  return (
    <ThemeIcon
      pos="absolute"
      right={-6}
      top={-6}
      radius="xl"
      size={size}
      variant="laurel"
    >
      <Image
        alt="laurel"
        src="/icons/laurel-wreath.svg"
        style={{
          height: '80%',
          width: '80%',
        }}
      />
    </ThemeIcon>
  );
};
