import { ActionIcon, Group, Text } from '@mantine/core';
import { IconContext } from 'react-icons';
import { TbArrowLeft, TbX } from 'react-icons/tb';

import { CottageModalProps } from './default';
import { HeaderIcon, HeaderIconProps } from './modals/HeaderIcon';

type DefaultProps = Pick<CottageModalProps, 'onClose' | 'withCloseButton'> &
  HeaderIconProps;

type ModalHeaderWithBack = DefaultProps & {
  onBack?: () => void;
  stepNumber?: never;
  totalSteps?: never;
};

type ModalHeaderWithSteps = DefaultProps & {
  onBack?: never;
  stepNumber?: number;
  totalSteps?: number;
};

export type BaseModalHeaderProps = ModalHeaderWithBack | ModalHeaderWithSteps;

export const BaseModalHeader = ({
  icon,
  iconColor,
  onBack,
  onClose,
  stepNumber,
  totalSteps,
  withCloseButton,
}: BaseModalHeaderProps) => {
  const { firstRowLeftComponent, secondRowComponent } = getHeaderRowComponents({
    onBack,
    stepNumber,
    totalSteps,
    icon,
    iconColor,
  });

  const showFirstRow = firstRowLeftComponent || withCloseButton;

  return (
    <>
      {showFirstRow && (
        <Group position="apart" align="flex-start">
          {firstRowLeftComponent}
          <Group position="right" ml="auto">
            {withCloseButton && (
              <IconContext.Provider value={{ size: '24px' }}>
                <ActionIcon
                  variant="transparent"
                  onClick={onClose}
                  aria-label="close"
                >
                  <TbX />
                </ActionIcon>
              </IconContext.Provider>
            )}
          </Group>
        </Group>
      )}
      {secondRowComponent}
    </>
  );
};

const getHeaderRowComponents = ({
  onBack,
  stepNumber,
  totalSteps,
  icon,
  iconColor,
}: Omit<BaseModalHeaderProps, 'onClose'>) => {
  let firstRowLeftComponent;
  let secondRowComponent;
  if (onBack) {
    firstRowLeftComponent = (
      <Group position="left">
        <IconContext.Provider value={{ size: '24px' }}>
          <ActionIcon variant="transparent" onClick={onBack} aria-label="back">
            <TbArrowLeft />
          </ActionIcon>
        </IconContext.Provider>
      </Group>
    );
  } else if (stepNumber !== undefined && totalSteps) {
    firstRowLeftComponent = (
      <Text size="xs" c="blue.6">
        {stepNumber} of {totalSteps}
      </Text>
    );
  }

  if (!firstRowLeftComponent) {
    // No other element is shown on the left side, so display the Icon there.
    firstRowLeftComponent = <HeaderIcon icon={icon} iconColor={iconColor} />;
  } else {
    secondRowComponent = <HeaderIcon icon={icon} iconColor={iconColor} />;
  }

  return { firstRowLeftComponent, secondRowComponent };
};
