import { LoadingOverlay, Radio, Stack, Text } from '@mantine/core';
import { CompanyAvailability } from '@prisma/client';
import { useEffect, useState } from 'react';
import { mutate } from 'swr';

import { GET_USER_COMPANY_URL } from '@/lib/constants/urlPaths';
import { useUpdateUserCompany } from '@/lib/hooks/useUpdateUserCompany';

import BaseModal from '../shared/BaseModal';
import ActionButton from '../shared/buttons/ActionButton';
import { CottageRadioCard } from '../shared/default';
import {
  showErrorNotification,
  showSuccessNotification,
} from '../shared/Notification';
import { AvailabilityBadge } from './AvailabilityBadge';

type ChangeAvailabilityModalProps = {
  onClose: () => void;
  opened: boolean;
  availability?: CompanyAvailability;
  isLoading?: boolean;
};

export const ChangeAvailabilityModal = ({
  onClose,
  opened,
  availability,
  isLoading = false,
}: ChangeAvailabilityModalProps) => {
  const { updateUserCompany, loading: loadingUpdateUserCompany } =
    useUpdateUserCompany();

  const [selectedAvailability, setSelectedAvailability] =
    useState(availability);

  // Set selected availability value to DB value on modal open
  useEffect(() => {
    if (opened && availability !== undefined) {
      setSelectedAvailability(availability);
    }
  }, [availability, opened]);

  const handleSaveAvailability = () => {
    const saveAvailability = async () => {
      if (!selectedAvailability) {
        return;
      }

      try {
        await updateUserCompany({ availability: selectedAvailability });
        void mutate(GET_USER_COMPANY_URL);
        onClose();
        showSuccessNotification("Your company's availability has been updated");
      } catch (error) {
        showErrorNotification((error as Error).message);
      }
    };

    void saveAvailability();
  };

  return (
    <BaseModal
      opened={opened}
      onClose={onClose}
      title="Change Availability"
      subtitle="Only you can see this status, and it will not impact your existing projects."
      primaryAction={
        <ActionButton
          onClick={handleSaveAvailability}
          disabled={selectedAvailability === availability}
          loading={loadingUpdateUserCompany}
        >
          Save
        </ActionButton>
      }
      withCloseButton
    >
      <Radio.Group
        value={selectedAvailability}
        onChange={(value) =>
          setSelectedAvailability(value as CompanyAvailability)
        }
      >
        <Stack>
          <CottageRadioCard
            value={CompanyAvailability.AVAILABLE}
            label={
              <Stack spacing="xxs">
                <AvailabilityBadge
                  availability={CompanyAvailability.AVAILABLE}
                />
                <Text>
                  We will keep matching your company with new project
                  opportunities.
                </Text>
              </Stack>
            }
            disabled={loadingUpdateUserCompany}
            radioVerticalAlignment="start"
            onHoverBackgroundColor="transparent"
            onSelectedBackgroundColor="transparent"
          />
          <CottageRadioCard
            value={CompanyAvailability.UNAVAILABLE}
            label={
              <Stack spacing="xxs">
                <AvailabilityBadge
                  availability={CompanyAvailability.UNAVAILABLE}
                />
                <Text>
                  Your company will not be matched with new project
                  opportunities until turned back on.
                </Text>
              </Stack>
            }
            disabled={loadingUpdateUserCompany}
            radioVerticalAlignment="start"
            onHoverBackgroundColor="transparent"
            onSelectedBackgroundColor="transparent"
          />
        </Stack>
      </Radio.Group>
      <LoadingOverlay visible={isLoading} overlayBlur={2} />
    </BaseModal>
  );
};
