import '@/styles/globals.css';

import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { MantineProvider, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import { captureException } from '@sentry/nextjs';
import {
  useLDClient,
  useLDClientError,
  withLDProvider,
} from 'launchdarkly-react-client-sdk';
import type { NextComponentType } from 'next'; //Import Component type
import type { AppProps } from 'next/app';
import { SessionProvider, useSession } from 'next-auth/react';
import { FunctionComponent, ReactNode, useEffect } from 'react';
import { SWRConfig, SWRConfiguration } from 'swr';

import { Layout } from '@/components/layout/Layout';
import {
  LayoutContext,
  LayoutType,
} from '@/components/shared/contexts/LayoutContext';
import { PageOffsetProvider } from '@/components/shared/contexts/PageOffsetContext';
import { SupportModalProvider } from '@/components/shared/contexts/SupportModalContext';
import { cottageTheme } from '@/lib/cottageTheme';

const defaultSWRConfig: SWRConfiguration = {
  revalidateOnFocus: false,
  shouldRetryOnError: false,
};

type ComponentProps = { auth?: boolean; hideNav?: boolean };

// Add custom appProp type then use union to add it
type CustomAppProps = AppProps & {
  Component: NextComponentType & ComponentProps;
};

const App = ({
  Component,
  pageProps: { session, ...pageProps },
}: CustomAppProps) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      void initializeAmplitude();
    }
  }, []);

  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const isTablet = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const isSmallDesktop = useMediaQuery(`(max-width: ${theme.breakpoints.lg})`);
  let layoutType = LayoutType.DESKTOP;
  if (isMobile) {
    layoutType = LayoutType.MOBILE;
  } else if (isTablet) {
    layoutType = LayoutType.TABLET;
  } else if (isSmallDesktop) {
    layoutType = LayoutType.SMALL_DESKTOP;
  }
  const hideNav = Component.hideNav;

  return (
    <SessionProvider session={session}>
      <SWRConfig value={defaultSWRConfig}>
        <MantineProvider
          withGlobalStyles
          withNormalizeCSS
          theme={cottageTheme()}
        >
          <SupportModalProvider>
            <Notifications position="bottom-right" />
            <ModalsProvider>
              <LayoutContext.Provider value={{ layout: layoutType }}>
                <PageOffsetProvider>
                  {Component.auth ? (
                    <Auth>
                      <Layout includeNav={!hideNav}>
                        <Component {...pageProps} />
                      </Layout>
                    </Auth>
                  ) : (
                    <Layout includeNav={!hideNav}>
                      <Component {...pageProps} />
                    </Layout>
                  )}
                </PageOffsetProvider>
              </LayoutContext.Provider>
            </ModalsProvider>
          </SupportModalProvider>
        </MantineProvider>
      </SWRConfig>
    </SessionProvider>
  );
};

export default withLDProvider({
  clientSideID: process.env.LAUNCHDARKLY_CLIENT_ID ?? '',
  user: {
    anonymous: true,
  },
  options: {
    bootstrap: 'localStorage',
  },
})(App as FunctionComponent);

interface AuthProps {
  children: ReactNode; // Specify the prop type as ReactNode
}

const Auth = ({ children }: AuthProps) => {
  // If `{ required: true }` is supplied, `status` can only be "loading" or "authenticated"
  const { status, data } = useSession({ required: true });

  const ldClient = useLDClient();
  const ldClientError = useLDClientError();

  useEffect(() => {
    // Initialize user feature flags from LaunchDarkly
    if (data?.user && ldClient) {
      void ldClient.identify({
        kind: 'user',
        key: data?.user.userId,
      });
    }
  }, [data?.user, ldClient]);

  useEffect(() => {
    if (ldClientError) {
      captureException(
        `Launchdarkly client initialization error: ${JSON.stringify(ldClientError)}`
      );
    }
  }, [ldClientError]);

  if (status === 'loading') {
    return <></>;
  }

  return <>{children}</>;
};

const initializeAmplitude = async () => {
  const AMPLITUDE_API_KEY = process.env.AMPLITUDE_API_KEY;
  const NEXT_PUBLIC_AMPLITUDE_EVENTS_API_URL =
    process.env.NEXT_PUBLIC_AMPLITUDE_EVENTS_API_URL;

  if (!AMPLITUDE_API_KEY) {
    throw new Error('Missing AMPLITUDE_API_KEY');
  }
  if (!NEXT_PUBLIC_AMPLITUDE_EVENTS_API_URL) {
    throw new Error('Missing NEXT_PUBLIC_AMPLITUDE_EVENTS_API_URL');
  }
  const sessionReplayTracking = sessionReplayPlugin({
    sampleRate: 1, // 100% sample rate, reduce if necessary
  });

  await amplitude.add(sessionReplayTracking).promise;
  amplitude.init(AMPLITUDE_API_KEY, {
    defaultTracking: { pageViews: true, sessions: true },
    serverUrl: NEXT_PUBLIC_AMPLITUDE_EVENTS_API_URL,
  });
};
