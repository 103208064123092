import { createStyles, Flex, Group, Stack } from '@mantine/core';
import { useRouter } from 'next/router';
import { useContext } from 'react';

import { Role } from '@/lib/constants/role';
import { useCottageFlags } from '@/lib/hooks/useCottageFlags';
import { Project } from '@/types/api/projects';

import ActionBar from '../shared/ActionBar';
import { LayoutContext, LayoutType } from '../shared/contexts/LayoutContext';
import { PageOffsetContext } from '../shared/contexts/PageOffsetContext';
import NavLink from './NavigationMenuLink';
import UserMenu, { UserMenuCompactDisplayStrategy } from './UserMenu';

type TabInfo = {
  path: string;
  title: string;
  badge?: string;
  gap?: string;
};

export const TAB_INFOS: Record<string, TabInfo> = {
  APPROVALS: {
    path: '/approvals',
    title: 'Approvals',
  },
  HOME: {
    path: '/',
    title: 'Home',
    gap: '2rem',
  },
  INVOICES: {
    path: '/invoices',
    title: 'Invoices',
  },
  PROCUREMENT: {
    path: '/procurement',
    title: 'Procurement',
    badge: 'BETA',
  },
  PROJECTS: {
    path: '/projects',
    title: 'Projects',
  },
  QUOTES_AND_ORDERS: {
    path: '/procurement',
    title: 'Quotes & Orders',
  },
  WORK_ORDERS: {
    path: '/workorders',
    title: 'Work Orders',
  },
};

type MenuTabProps = {
  setIsMobileNavOpen: (isOpen: boolean) => void;
  tabInfo: TabInfo;
};

const MenuTab = ({ tabInfo, setIsMobileNavOpen }: MenuTabProps) => {
  const router = useRouter();

  let isSelected = router.asPath.includes(tabInfo.path);
  // treat the home page special
  if (tabInfo.path === '/') {
    isSelected = router.asPath === '/';
  }

  return (
    <NavLink
      path={tabInfo.path}
      title={tabInfo.title}
      isSelected={isSelected}
      toggleIsMobileNavOpen={setIsMobileNavOpen}
      badge={tabInfo.badge}
      gap={tabInfo.gap}
    />
  );
};

const useMenuStyles = createStyles(
  (theme, { offsetTop }: { offsetTop: number }) => ({
    mobileContainer: {
      width: '100%',
      flexDirection: 'column',
      background: 'white',
      position: 'absolute',
      top: offsetTop,
      left: 0,
      height: '100vh',
    },
    mobileTabs: {
      gap: theme.spacing.md,
      height: 'auto',
      padding: theme.spacing.sm,
      width: '100%',
      background: 'white',
      color: theme.colors.blue[8],
    },
    tabsContainer: {
      height: '100%',
      gap: theme.spacing.xl,
    },
    tabsContainerTablet: {
      height: '100%',
      gap: theme.spacing.xs,
    },
  })
);

type MenuProps = {
  hasRenofiAccount: boolean;
  setIsMobileNavOpen: (isOpen: boolean) => void;
  userEmail: string;
  userName: string;
  project?: Project;
  role?: Role;
};

const Menu = ({
  role,
  userEmail,
  userName,
  setIsMobileNavOpen,
  hasRenofiAccount,
}: MenuProps) => {
  const { layout } = useContext(LayoutContext);
  const isMobile = layout === LayoutType.MOBILE;
  const isTablet = layout === LayoutType.TABLET;

  const {
    gcProcurementsMigration: gcProcurementsMigrationFlag,
    xsell: xsellFlag,
  } = useCottageFlags();

  const { offsetTop } = useContext(PageOffsetContext);

  const { classes } = useMenuStyles({ offsetTop: xsellFlag ? offsetTop : 0 });

  const showGcProcurementTab =
    role === Role.GC && !!gcProcurementsMigrationFlag;

  let tabs: React.JSX.Element;
  if (!role) {
    tabs = <></>;
  } else if (role === Role.CONSULTANT) {
    tabs = (
      <>
        <MenuTab
          tabInfo={TAB_INFOS.HOME}
          setIsMobileNavOpen={setIsMobileNavOpen}
        />
        <MenuTab
          tabInfo={TAB_INFOS.PROJECTS}
          setIsMobileNavOpen={setIsMobileNavOpen}
        />
        <MenuTab
          tabInfo={TAB_INFOS.INVOICES}
          setIsMobileNavOpen={setIsMobileNavOpen}
        />
        <MenuTab
          tabInfo={TAB_INFOS.WORK_ORDERS}
          setIsMobileNavOpen={setIsMobileNavOpen}
        />
      </>
    );
  } else if (role === Role.GC) {
    tabs = (
      <>
        <MenuTab
          tabInfo={TAB_INFOS.HOME}
          setIsMobileNavOpen={setIsMobileNavOpen}
        />
        <MenuTab
          tabInfo={TAB_INFOS.PROJECTS}
          setIsMobileNavOpen={setIsMobileNavOpen}
        />
        <MenuTab
          tabInfo={TAB_INFOS.INVOICES}
          setIsMobileNavOpen={setIsMobileNavOpen}
        />
        <MenuTab
          tabInfo={TAB_INFOS.APPROVALS}
          setIsMobileNavOpen={setIsMobileNavOpen}
        />
        {showGcProcurementTab && (
          <MenuTab
            tabInfo={TAB_INFOS.PROCUREMENT}
            setIsMobileNavOpen={setIsMobileNavOpen}
          />
        )}
      </>
    );
  } else if (role === Role.SUPPLIER) {
    tabs = (
      <>
        <MenuTab
          tabInfo={TAB_INFOS.QUOTES_AND_ORDERS}
          setIsMobileNavOpen={setIsMobileNavOpen}
        />
        <MenuTab
          tabInfo={TAB_INFOS.INVOICES}
          setIsMobileNavOpen={setIsMobileNavOpen}
        />
      </>
    );
  } else if (role === Role.ARCHITECT) {
    tabs = (
      <>
        <MenuTab
          tabInfo={TAB_INFOS.HOME}
          setIsMobileNavOpen={setIsMobileNavOpen}
        />
        <MenuTab
          tabInfo={TAB_INFOS.PROJECTS}
          setIsMobileNavOpen={setIsMobileNavOpen}
        />
        <MenuTab
          tabInfo={TAB_INFOS.INVOICES}
          setIsMobileNavOpen={setIsMobileNavOpen}
        />
        <MenuTab
          tabInfo={TAB_INFOS.APPROVALS}
          setIsMobileNavOpen={setIsMobileNavOpen}
        />
      </>
    );
  } else {
    tabs = (
      <MenuTab
        tabInfo={TAB_INFOS.PROJECTS}
        setIsMobileNavOpen={setIsMobileNavOpen}
      />
    );
  }

  if (isMobile) {
    return (
      <Flex
        align="center"
        justify="flex-start"
        className={classes.mobileContainer}
      >
        <Stack className={classes.mobileTabs}>{tabs}</Stack>
        <ActionBar padding="0px" withShadow={false} updateOffset={true}>
          <UserMenu
            userName={userName}
            userEmail={userEmail}
            displayStrategy={UserMenuCompactDisplayStrategy.FULL_WIDTH}
            hasRenofiAccount={hasRenofiAccount}
          />
        </ActionBar>
      </Flex>
    );
  }

  return (
    <Group
      className={isTablet ? classes.tabsContainerTablet : classes.tabsContainer}
      noWrap
    >
      {tabs}
    </Group>
  );
};

export default Menu;
