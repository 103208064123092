const CottageLogoCompact = ({ color = 'white' }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Cottage Logo Small</title>
    <g clipPath="url(#clip0_2822_5195)">
      <path
        d="M12.5151 23.612C15.9646 23.612 18.6708 21.676 19.7339 18.5092C19.7691 18.4016 19.711 18.2849 19.6054 18.248L18.3648 17.8101C18.2562 17.7716 18.1399 17.8316 18.1017 17.9407C17.2389 20.4652 15.1845 21.9541 12.5151 21.9541C9.1068 21.9541 6.43739 19.1853 6.43739 15.6497C6.43739 12.1033 9.1068 9.32528 12.5151 9.32528C15.1325 9.32528 17.1609 10.7666 18.0527 13.2173C18.0895 13.3203 18.1996 13.3787 18.3036 13.3479L19.5978 12.9653C19.7095 12.9315 19.7737 12.8102 19.7355 12.6995C18.6708 9.61876 15.9065 7.66736 12.5151 7.66736C8.11399 7.66736 4.66748 11.1737 4.66748 15.6497C4.66901 20.1149 8.11552 23.612 12.5151 23.612Z"
        fill={color}
      />
      <path
        d="M1.56799 23.592H0V8.7506L11.9182 0L23.786 7.31547L22.9615 8.65533L11.9794 1.88687L1.56799 9.56343V23.592Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_2822_5195">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default CottageLogoCompact;
