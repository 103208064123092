import {
  CompanyAvailability,
  CompanyType,
  ProjectAccessLevel,
} from '@prisma/client';

import { Company } from '@/types/api/company';

export const EMPTY_COMPANY: Company = Object.freeze({
  id: 0,
  name: '',
  email: '',
  profile_picture: '',
  stripeAccountId: '',
  hasPayoutsEnabled: false,
  company_type: CompanyType.GC,
  users: [],
  about_info: '',
  city: '',
  state: '',
  availability: CompanyAvailability.UNAVAILABLE,
});

export const PRO_ON_PROJECT_PROJECT_ACCESS_LEVELS: Readonly<
  Array<ProjectAccessLevel>
> = Object.freeze([
  ProjectAccessLevel.FULL,
  ProjectAccessLevel.PRE_CLIENT_ACCEPTANCE,
]);
