import { ActionIcon, Anchor, Group, Text } from '@mantine/core';
import { captureException } from '@sentry/nextjs';
import Image from 'next/image';
import { useContext } from 'react';
import { TbX } from 'react-icons/tb';
import { KeyedMutator } from 'swr';

import { dismissNotification } from '@/lib/api/client/notifications';
import { useContainerStyles } from '@/lib/styleUtils';
import { Notification } from '@/types/api/notifications';

import { DisplaySize, SizeContext } from '../shared/contexts/SizeContext';

type MergerAnnouncementBannerProps = {
  notificationUuid: string;
  reloadNotifications: KeyedMutator<Notification[]>;
};

export const MergerAnnouncementBanner = ({
  notificationUuid,
  reloadNotifications,
}: MergerAnnouncementBannerProps) => {
  const { size } = useContext(SizeContext);
  const isDefaultSize = size === DisplaySize.DEFAULT;
  const alignment = isDefaultSize ? 'center' : 'left';
  const padding = isDefaultSize ? 'xxs' : 'md';

  const { classes } = useContainerStyles();

  const handleDismiss = () => {
    const dismiss = async () => {
      try {
        await dismissNotification(notificationUuid);
        void reloadNotifications();
      } catch (error) {
        captureException(error);
      }
    };

    void dismiss();
  };

  return (
    <Group
      bg="yellow.0"
      align="center"
      position={alignment}
      p={padding}
      style={{ alignContent: 'center' }}
      className={classes.gapMd}
    >
      {isDefaultSize && (
        <Image
          width={68}
          height={40}
          src="/images/CottageRenofiLogos.png"
          alt="Cottage and Renofi logos"
        />
      )}
      <Text fw="bold">
        We&apos;ve joined forces with RenoFi to bring the best financing options
        for your project!
      </Text>
      <Anchor
        href="https://www.renofi.com/cottage-and-renofi-join-forces?utm=cottage_platform"
        target="_blank"
        rel="noreferrer"
        style={{ textDecoration: 'underline' }}
      >
        Learn More
      </Anchor>
      <ActionIcon
        onClick={handleDismiss}
        style={{ position: 'absolute', top: '4px', right: '4px' }}
      >
        <TbX />
      </ActionIcon>
    </Group>
  );
};
