import { Media } from '@/types/api/media';
import { useMemo } from 'react';
import { usePrevious } from 'react-use';

type UseMostRecentSelectedSlideIndex = {
  mediaArray?: Media[];
  selectedMedia?: Media;
};

/**
 * Returns the index of the most recently selected media in a media array. Used
 * for preserving the most recently selected carousel location in the company
 * profile modals.
 */
export const useMostRecentSelectedSlide = ({
  mediaArray,
  selectedMedia,
}: UseMostRecentSelectedSlideIndex) => {
  const selectedSlideIndex = useMemo(() => {
    const result = mediaArray?.findIndex(
      (media) => media.id === selectedMedia?.id
    );

    if (selectedMedia === undefined || result === undefined || result < 0) {
      return undefined;
    }

    return result;
  }, [mediaArray, selectedMedia]);

  const prevSelectedSlideIndex = usePrevious(selectedSlideIndex);

  return selectedSlideIndex !== undefined
    ? selectedSlideIndex
    : prevSelectedSlideIndex;
};
