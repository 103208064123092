import { MantineNumberSize, Text } from '@mantine/core';

import BaseModal from './BaseModal';
import ActionButton from './buttons/ActionButton';

type OkModalProps = {
  okButtonText: string;
  onOk: () => void;
  opened: boolean;
  title: string;
  avatarColor?: string;
  children?: React.ReactNode;
  icon?: React.JSX.Element;
  size?: MantineNumberSize;
  subtitle?: string;
  withCloseButton?: boolean;
};

function OkModal({
  okButtonText,
  onOk,
  opened,
  title,
  avatarColor = 'green',
  children,
  icon,
  size = 'md',
  subtitle,
  withCloseButton = false,
}: OkModalProps) {
  return (
    <BaseModal
      opened={opened}
      onClose={onOk}
      title={title}
      subtitle={subtitle}
      icon={icon}
      iconColor={avatarColor}
      primaryAction={
        <ActionButton onClick={() => onOk()}>{okButtonText}</ActionButton>
      }
      withCloseButton={withCloseButton}
      size={size ?? 'md'}
    >
      <Text fz="sm">{children}</Text>
    </BaseModal>
  );
}

export default OkModal;
