import { AxiosError } from 'axios';

import { CreateNotificationRequest } from '@/types/api/notifications';
import { HttpError } from '@/types/utilityTypes';

import { api } from './axios';

export const createNotification = async (req: CreateNotificationRequest) => {
  try {
    const response = await api.post<Notification | undefined>(
      '/api/notifications/create',
      req
    );

    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError<HttpError>;
    throw new Error(
      `Failed to create invoice ${axiosError.response?.data.message}`
    );
  }
};

export const dismissNotification = async (uuid: string) => {
  try {
    const response = await api.patch<void>(
      `/api/notifications/${uuid}/dismiss`
    );

    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError<HttpError>;
    throw new Error(
      `Failed to update notification with error: ${axiosError.response?.data.message}`
    );
  }
};
