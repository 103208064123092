import { CompanyType, StaticAttribute } from '@prisma/client';
import {
  TbAward,
  TbCertificate,
  TbMap2,
  TbRotateDot,
  TbSofa,
  TbTools,
  TbTrophy,
  TbVideo,
} from 'react-icons/tb';

import { Attribute, AttributeProps } from './Attribute';

type BaseCompanyAttributeProps = {
  companyType: CompanyType;
};

type SimpleAttributeProps = {
  attributeType: StaticAttribute | 'MUNICIPALITY_EXPERIENCE';
  projectsCount?: never;
} & BaseCompanyAttributeProps;

type CompletedProjectsAttributeProps = {
  attributeType: 'COMPLETED_PROJECTS';
  projectsCount: number;
} & BaseCompanyAttributeProps;

type CompanyAttributeProps =
  | SimpleAttributeProps
  | CompletedProjectsAttributeProps;

export const CompanyAttribute = ({
  attributeType,
  companyType,
  projectsCount,
}: CompanyAttributeProps) => {
  const getAttributeProps = () => {
    const tooltipText = getTooltipText(attributeType, companyType);

    const iconAndTextMap: Record<
      CompanyAttributeProps['attributeType'],
      AttributeProps
    > = {
      LICENSED_ARCHITECT: {
        icon: <TbCertificate size={24} color="blue.9" />,
        text: 'Licensed Architect',
      },
      DESIGN_BUILD: {
        icon: <TbTools size={24} color="blue.9" />,
        text: 'Design-Build',
      },
      REMOTE_DESIGNER: {
        icon: <TbVideo size={24} color="blue.9" />,
        text: 'Remote Designer',
      },
      FULL_SERVICE: {
        icon: <TbRotateDot size={24} color="blue.9" />,
        text: 'Full Service',
      },
      AWARD_WINNING: {
        icon: <TbAward size={24} color="blue.9" />,
        text: 'Award-Winning',
      },
      INTERIOR_DESIGNER: {
        icon: <TbSofa size={24} color="blue.9" />,
        text: 'Interior Designer',
      },
      MUNICIPALITY_EXPERIENCE: {
        icon: <TbMap2 size={24} color="blue.9" />,
        text: 'Experienced in your area',
      },
      COMPLETED_PROJECTS: {
        icon: <TbTrophy color="blue.9" size={24} />,
        text: getCompletedProjectCountText(projectsCount),
      },
    };

    return { ...iconAndTextMap[attributeType], tooltipText };
  };

  return <Attribute {...getAttributeProps()} />;
};

const getTooltipText = (
  attributeType: CompanyAttributeProps['attributeType'],
  companyType: CompanyType
) => {
  switch (attributeType) {
    case 'LICENSED_ARCHITECT':
      return 'Holds an architecture license in at least one state in the US.';
    case 'DESIGN_BUILD':
      return 'Offers in-house services for both design and construction. Enables close coordination to meet design intent and budget.';
    case 'REMOTE_DESIGNER':
      return 'Remote designers may be more price competitive. Expect to conduct design meetings over video calls and interact online.';
    case 'FULL_SERVICE':
      return 'Offers interior design and construction administration.';
    case 'AWARD_WINNING': {
      switch (companyType) {
        case CompanyType.ARCHITECT:
          return 'Designers with exceptional taste and award-winning work.';
        case CompanyType.GC:
          return 'Contractors with award-winning work.';
        default:
          return '';
      }
    }
    case 'INTERIOR_DESIGNER':
      return 'Offers interior design service.';
    case 'MUNICIPALITY_EXPERIENCE': {
      switch (companyType) {
        case CompanyType.ARCHITECT:
          return 'Relevant project experience in your municipality including local permitting processes.';
        case CompanyType.GC:
        default:
          return 'Relevant project experience in your municipality.';
      }
    }
    case 'COMPLETED_PROJECTS': {
      switch (companyType) {
        case CompanyType.ARCHITECT:
          return 'Number of projects that this designer has designed and done permitting for real clients.';
        case CompanyType.GC:
          return 'Number of projects that this contractor has completed for real clients.';
        default:
          return '';
      }
    }
  }
};

const getCompletedProjectCountText = (count?: number) => {
  if (!count) {
    return '';
  }

  if (count >= 30) {
    return '30+ completed projects';
  } else if (count >= 20) {
    return '20+ completed projects';
  } else if (count >= 10) {
    return '10+ completed projects';
  } else if (count >= 5) {
    return '5+ completed projects';
  }

  return '';
};

export const hasExperienceInMunicipality = (
  experiencedMunicipalities?: string[],
  projectMunicipality?: string
) => {
  return (
    !!projectMunicipality &&
    !!experiencedMunicipalities &&
    experiencedMunicipalities.includes(projectMunicipality)
  );
};
