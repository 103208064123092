import {
  Anchor,
  Box,
  DefaultMantineColor,
  Divider,
  Group,
} from '@mantine/core';

import { useCottageFlags } from '@/lib/hooks/useCottageFlags';

import CottageLogoCompact from '../shared/svg/CottageLogoCompact';
import RenofiLogo from '../shared/svg/RenofiLogo';

type XsellLogoProps = {
  color: DefaultMantineColor;
  containerStyle?: React.CSSProperties;
  hasRenofiAccount?: boolean;
};

export const XsellLogo = ({
  color,
  containerStyle,
  hasRenofiAccount = false,
}: XsellLogoProps) => {
  const { xsell: xsellFlag } = useCottageFlags();

  return (
    <Group style={containerStyle}>
      <Anchor w={24} href="/">
        <CottageLogoCompact color={color} />
      </Anchor>
      {xsellFlag && (
        <>
          <Divider orientation="vertical" size="xs" color={color} />
          <Box
            w={100}
            component={hasRenofiAccount ? 'a' : 'div'}
            href={hasRenofiAccount ? 'https://dashboard.renofi.com' : undefined}
            target="_blank"
          >
            <RenofiLogo color={color} />
          </Box>
        </>
      )}
    </Group>
  );
};
