const RenofiLogo = ({ color = 'white' }) => (
  <svg
    width="70"
    height="20"
    viewBox="0 0 70 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Renofi Logo</title>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M31.2423 2.7038L25.0813 6.54189C24.9084 6.64981 24.8027 6.84349 24.8027 7.05237V7.05237V19.5329C24.8027 19.6979 24.9319 19.8315 25.0913 19.8315V19.8315H28.1598C28.3191 19.8315 28.4482 19.6979 28.4482 19.5334V19.5334V9.10476C28.4482 8.89171 28.5579 8.69482 28.7364 8.58824V8.58824L31.2423 7.09143C31.4206 6.98492 31.6406 6.98492 31.8189 7.09143V7.09143L34.3248 8.58824C34.5033 8.69482 34.6132 8.89171 34.6132 9.10476V9.10476V19.5334C34.6132 19.6979 34.7422 19.8315 34.9014 19.8315V19.8315H37.9578C38.1173 19.8315 38.2467 19.6979 38.2467 19.5329V19.5329L38.2464 7.05192C38.2464 6.84322 38.1407 6.64981 37.9681 6.54189V6.54189L31.8189 2.7038C31.7296 2.65055 31.6301 2.6239 31.5305 2.6239V2.6239C31.431 2.6239 31.3315 2.65055 31.2423 2.7038V2.7038Z"
      fill="#FF5253"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.0957 12.7285C9.0957 16.7452 12.1052 20.0014 15.8175 20.0014V20.0014C18.5464 20.0014 20.8942 18.2411 21.947 15.7134V15.7134C22.0234 15.5301 21.8967 15.3246 21.7037 15.3246V15.3246H18.8211C18.6433 15.3246 18.4778 15.4166 18.3802 15.5703V15.5703C17.9202 16.2955 17.1415 16.8557 15.8175 16.8557V16.8557C13.7384 16.8557 12.9992 15.4762 12.7363 14.2422V14.2422C12.6999 14.0712 12.8281 13.9098 12.9972 13.9098V13.9098H21.9785C22.2519 13.9098 22.484 13.6959 22.5088 13.4144V13.4144C22.5287 13.1886 22.5392 12.9598 22.5392 12.7285V12.7285C22.5392 8.71175 19.5297 5.45557 15.8175 5.45557V5.45557C12.1052 5.45557 9.0957 8.71175 9.0957 12.7285V12.7285ZM12.8367 10.8178C13.1713 9.70248 13.9587 8.6006 15.8169 8.6006V8.6006C17.675 8.6006 18.4627 9.70248 18.7969 10.8178V10.8178C18.8496 10.9936 18.7191 11.1715 18.5413 11.1715V11.1715H13.0927C12.9146 11.1715 12.7842 10.9936 12.8367 10.8178V10.8178Z"
      fill={color}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M40.5112 12.716C40.5112 16.7397 43.6643 20.0014 47.5537 20.0014V20.0014C51.4427 20.0014 54.5956 16.7397 54.5956 12.716V12.716C54.5956 8.69236 51.4427 5.43066 47.5537 5.43066V5.43066C43.6643 5.43066 40.5112 8.69236 40.5112 12.716V12.716ZM47.5503 16.6361C45.0555 16.6327 44.1572 14.4765 44.1572 12.7127V12.7127C44.1572 10.9477 45.0568 8.78917 47.5557 8.78917V8.78917V8.79517C50.0502 8.79817 50.9487 10.9546 50.9487 12.7183V12.7183C50.9487 14.4834 50.0491 16.6419 47.5503 16.6419V16.6419V16.6361Z"
      fill={color}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M65.7764 2.18725C65.7764 3.39524 66.7232 4.37426 67.8906 4.37426V4.37426C69.0583 4.37426 70.0047 3.39524 70.0047 2.18725V2.18725C70.0047 0.979223 69.0583 0 67.8906 0V0C66.7232 0 65.7764 0.979223 65.7764 2.18725V2.18725Z"
      fill={color}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M66.3479 6.13501C66.2011 6.13501 66.082 6.2582 66.082 6.41004V6.41004V19.7264C66.082 19.8782 66.2011 20.0014 66.3479 20.0014V20.0014H69.4339C69.5807 20.0014 69.6995 19.8782 69.6995 19.7264V19.7264V6.41004C69.6995 6.2582 69.5807 6.13501 69.4339 6.13501V6.13501H66.3479Z"
      fill={color}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M58.1907 1.30574C57.3918 2.14881 57.0083 3.19822 57.0083 4.48243V4.48243V5.8161C57.0083 5.96887 56.8887 6.09275 56.7409 6.09275V6.09275H55.9968C55.8498 6.09275 55.731 6.21567 55.731 6.36774V6.36774V8.84837C55.731 9.00017 55.8498 9.12336 55.9965 9.12336V9.12336H56.7409C56.8887 9.12336 57.0083 9.24721 57.0083 9.39998V9.39998V19.7264C57.0083 19.8798 57.1298 20.0035 57.2779 20.0014V20.0014L60.3129 19.9563C60.458 19.9543 60.5749 19.8315 60.5749 19.6813V19.6813V9.40208C60.5749 9.25024 60.6939 9.12705 60.8407 9.12705V9.12705H62.66C62.807 9.12705 62.926 9.00386 62.926 8.85206V8.85206V6.37122C62.926 6.21935 62.8072 6.0962 62.6602 6.0962V6.0962H60.8448C60.6964 6.0962 60.5767 5.97094 60.5783 5.81772V5.81772L60.5934 4.3752C60.5934 4.3752 60.5646 3.71288 61.1347 3.36464V3.36464C61.5178 3.13052 62.7719 3.11736 63.327 3.12359V3.12359C63.4749 3.12518 63.5951 3.00226 63.5951 2.84926V2.84926C63.5951 2.25673 63.5953 0.884009 63.5953 0.321717V0.321717C63.5951 0.179636 63.4921 0.0608219 63.3552 0.0478616V0.0478616C63.1312 0.0264563 62.7651 0.00012207 62.3219 0.00012207V0.00012207C61.0806 8.76014e-05 59.2323 0.206522 58.1907 1.30574V1.30574Z"
      fill={color}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.15859 7.07032C4.15659 7.07218 4.15456 7.07377 4.15279 7.07563V7.07563C3.94056 7.26052 3.61752 7.11006 3.61752 6.82328V6.82328V6.40917C3.61752 6.2573 3.49868 6.13414 3.35192 6.13414V6.13414H0.265832C0.119074 6.13414 0 6.2573 0 6.40917V6.40917V19.7255C0 19.8774 0.119074 20.0005 0.265832 20.0005V20.0005H3.35192C3.49868 20.0005 3.61752 19.8774 3.61752 19.7255V19.7255V15.3964V13.2869C3.61752 12.151 3.70511 10.7732 4.56291 10.1021V10.1021C5.32936 9.50217 6.77556 9.55346 7.38901 9.60706V9.60706C7.5438 9.62051 7.67538 9.49363 7.67538 9.33304V9.33304V6.38766C7.67538 6.24182 7.56638 6.12077 7.42586 6.11312V6.11312C7.34181 6.10853 7.23901 6.10498 7.12134 6.10498V6.10498C6.39865 6.10498 5.11432 6.23779 4.15859 7.07032V7.07032Z"
      fill={color}
    />
  </svg>
);

export default RenofiLogo;
