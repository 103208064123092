import { createStyles } from '@mantine/core';
import { NotificationType } from '@prisma/client';
import { useSession } from 'next-auth/react';
import { ReactNode, useContext } from 'react';

import { PageOffsetContext } from '@/components/shared/contexts/PageOffsetContext';
import { Role } from '@/lib/constants/role';
import { useCottageFlags } from '@/lib/hooks/useCottageFlags';
import useNotifications from '@/lib/hooks/useNotifications';
import { getRole } from '@/lib/roleUtils';
import { zIndex } from '@/lib/styleUtils';

import { MergerAnnouncementBanner } from '../banners/MergerAnnouncementBanner';
import { Navigation } from '../navigation/Navigation';
import ActionBar, { ActionBarPosition } from '../shared/ActionBar';
import { LayoutContext, LayoutType } from '../shared/contexts/LayoutContext';
import { DisplaySize, SizeContext } from '../shared/contexts/SizeContext';
import { useSupportModal } from '../shared/contexts/SupportModalContext';
import { SupportModal } from '../shared/SupportModal';
import Meta from './meta';

const useStyles = createStyles(
  (
    theme,
    { offsetBottom, offsetTop }: { offsetBottom: number; offsetTop: number }
  ) => ({
    container: {
      background: theme.colors.blue[0],
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    },
    main: {
      background: theme.colors.blue[0],
      paddingBottom: offsetBottom,
      paddingTop: offsetTop,
    },
  })
);

export const Layout = ({
  meta,
  children,
  includeNav = true,
}: {
  children: ReactNode;
  includeNav?: boolean;
  meta?: {
    description?: string;
    image?: string;
    title?: string;
  };
}) => {
  const { offsetBottom, offsetTop } = useContext(PageOffsetContext);
  const { supportModalOpen, closeSupportModal, options } = useSupportModal();
  const { xsell: xsellFlag } = useCottageFlags();

  const { classes } = useStyles({ offsetBottom, offsetTop });

  const { layout } = useContext(LayoutContext);
  const isMobile = layout === LayoutType.MOBILE;

  const { data: session } = useSession();
  const role = getRole(session?.user?.roles ?? []);

  const {
    notifications = [],
    loading: loadingNotifications,
    error: errorNotifications,
    reloadNotifications,
  } = useNotifications({
    notificationType: NotificationType.MERGER_ANNOUNCEMENT,
    shouldCall: role === Role.CUSTOMER,
  });

  return (
    <div className={classes.container}>
      <Meta {...meta} />
      <SizeContext.Provider
        value={{ size: isMobile ? DisplaySize.COMPACT : DisplaySize.DEFAULT }}
      >
        <ActionBar
          position={ActionBarPosition.TOP}
          withShadow={false}
          padding="0"
          zIndex={zIndex.NAVIGATION}
          stackSpace={0}
          updateOffset
        >
          {includeNav &&
            xsellFlag &&
            !loadingNotifications &&
            !errorNotifications &&
            notifications.length > 0 && (
              <MergerAnnouncementBanner
                notificationUuid={notifications[0].uuid}
                reloadNotifications={reloadNotifications}
              />
            )}
          {includeNav && <Navigation session={session} />}
        </ActionBar>
        {!!session && (
          <SupportModal
            opened={supportModalOpen}
            onClose={closeSupportModal}
            defaults={options}
          />
        )}
      </SizeContext.Provider>
      <main className={classes.main}>{children}</main>
    </div>
  );
};
