import useSWR from 'swr';

import { Customer } from '@/types/api/users';

import { createGetFetcher } from '../api/client/fetchers';

const get = createGetFetcher('customer');

const useCustomer = (shouldCall = true) => {
  const { data, error, isLoading } = useSWR<Customer, Error>(
    shouldCall && '/api/user/customer',
    get
  );

  return {
    customer: data,
    error,
    loading: isLoading,
  };
};

export default useCustomer;
