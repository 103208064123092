import useSWRMutation from 'swr/mutation';

import { UpdateUserCompanyRequest } from '@/types/api/company';

import { createFetcherWithBody } from '../api/client/fetchers';
import { UPDATE_USER_COMPANY_URL } from '../constants/urlPaths';

const patch = createFetcherWithBody({
  method: 'PATCH',
  errorMessage: "Failed to update user's company",
});

/**
 * Updates the caller's company
 */
export const useUpdateUserCompany = () => {
  const { trigger, isMutating } = useSWRMutation(
    UPDATE_USER_COMPANY_URL,
    patch<UpdateUserCompanyRequest>
  );

  return {
    loading: isMutating,
    updateUserCompany: trigger,
  };
};
