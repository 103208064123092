import {
  reset as amplitudeReset,
  track as amplitudeTrack,
} from '@amplitude/analytics-browser';
import { Group, Menu, Text } from '@mantine/core';
import { signOut } from 'next-auth/react';
import { TbArrowNarrowRight, TbExchange, TbUserCircle } from 'react-icons/tb';

import { RenofiIcon } from '@/components/shared/svg/RenofiIcon';
import { AnalyticsEvent } from '@/lib/constants/analyticsEvents';
import { Company } from '@/types/api/company';

import { AvailabilityBadge } from '../company/AvailabilityBadge';
import { UserMenuCompactDisplayStrategy } from './UserMenu';

type UserMenuDropdownProps = {
  displayStrategy: UserMenuCompactDisplayStrategy;
  onClickChangeAvailability: () => void;
  onClickViewProfile: () => void;
  showOpenToWork: boolean;
  showProfileQuickView: boolean;
  showRenofiDashboardLink: boolean;
  userEmail: string;
  userName: string;
  company?: Company;
};

export const UserMenuDropdown = ({
  displayStrategy,
  onClickChangeAvailability,
  showRenofiDashboardLink,
  onClickViewProfile,
  showOpenToWork,
  showProfileQuickView,
  userName,
  userEmail,
  company,
}: UserMenuDropdownProps) => {
  const handleSignOut = () => {
    void signOut({ callbackUrl: '/auth/signin' }).then(() => {
      amplitudeTrack({
        event_type: AnalyticsEvent.SIGNED_OUT,
      });
      amplitudeReset();
    });
  };

  if (showOpenToWork && company) {
    return (
      <Menu.Dropdown>
        <Menu.Item disabled>
          <Text c="textPrimary.0" fw="bold">
            {userName}
          </Text>
          <Text c="textSecondary.0">{userEmail}</Text>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item disabled>
          <Text c="textPrimary.0" fw="bold">
            {company.name}
          </Text>
        </Menu.Item>
        <Menu.Item disabled pt={0}>
          <AvailabilityBadge availability={company.availability} />
        </Menu.Item>
        {showProfileQuickView && (
          <Menu.Item onClick={onClickViewProfile}>
            <Group>
              <TbUserCircle size={24} />
              <Text>View Profile</Text>
            </Group>
          </Menu.Item>
        )}
        <Menu.Item onClick={onClickChangeAvailability}>
          <Group>
            <TbExchange size={24} />
            <Text>Change Availability</Text>
          </Group>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item onClick={handleSignOut}>Log Out</Menu.Item>
      </Menu.Dropdown>
    );
  }

  return (
    <Menu.Dropdown>
      {displayStrategy === UserMenuCompactDisplayStrategy.ICON_ONLY && (
        <>
          <Menu.Item disabled>
            <Text c="textPrimary.0">{userName}</Text>
            <Text c="textSecondary.0">{userEmail}</Text>
          </Menu.Item>
          <Menu.Divider />
        </>
      )}
      {showProfileQuickView && (
        <>
          <Menu.Item onClick={onClickViewProfile}>
            <Group>
              <TbUserCircle size={24} />
              <Text>View Profile</Text>
            </Group>
          </Menu.Item>
          <Menu.Divider />
        </>
      )}
      {showRenofiDashboardLink && (
        <>
          <Menu.Item
            component="a"
            href="https://dashboard.renofi.com"
            target="_blank"
          >
            <Group position="apart">
              <Group>
                <RenofiIcon />
                <Text>Go to Renofi</Text>
              </Group>
              <TbArrowNarrowRight size={24} />
            </Group>
          </Menu.Item>
          <Menu.Divider />
        </>
      )}
      <Menu.Item onClick={handleSignOut}>Log Out</Menu.Item>
    </Menu.Dropdown>
  );
};
