export const RenofiIcon = () => {
  return (
    <svg
      width="19"
      height="24"
      viewBox="0 0 19 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.98155 0.111438L0.388536 5.46455C0.147376 5.61508 0 5.88521 0 6.17654V23.5835C0 23.8137 0.180126 24 0.402539 24H4.6822C4.90438 24 5.08446 23.8137 5.08446 23.5842V9.03908C5.08446 8.74193 5.23742 8.46732 5.4864 8.31867L8.98155 6.23101C9.23015 6.08246 9.53704 6.08246 9.78569 6.23101L13.2808 8.31867C13.5298 8.46732 13.6831 8.74193 13.6831 9.03908V23.5842C13.6831 23.8137 13.8629 24 14.085 24H18.3479C18.5703 24 18.7507 23.8137 18.7507 23.5835L18.7504 6.17592C18.7504 5.88482 18.603 5.61508 18.3621 5.46455L9.78569 0.111438C9.66121 0.0371621 9.52234 0 9.38348 0C9.24462 0 9.10585 0.0371621 8.98155 0.111438Z"
        fill="#FF5253"
      />
    </svg>
  );
};
