import {
  ActionIcon,
  createStyles,
  Group,
  Popover,
  ScrollArea,
  Stack,
  StackProps,
  Text,
} from '@mantine/core';
import { FormEventHandler, ReactNode } from 'react';
import { TbInfoCircle } from 'react-icons/tb';

import { useContainerStyles, zIndex } from '@/lib/styleUtils';

import { BaseModalHeader, BaseModalHeaderProps } from './BaseModalHeader';
import { ActionButtonProps } from './buttons/ActionButton';
import { LinkButtonProps } from './buttons/LinkButton';
import { TooltipButtonProps } from './buttons/TooltipButton';
import { DisplaySize, SizeContext } from './contexts/SizeContext';
import { CottageModal, CottageModalProps } from './default';

type ModalContainerProps = {
  children: React.ReactNode;
  formOnSubmit?: FormEventHandler<HTMLFormElement>;
  isForm?: boolean;
};
const ModalContainer = ({
  isForm,
  formOnSubmit,
  children,
}: ModalContainerProps) => {
  if (isForm) {
    return (
      <form name="modal-form" onSubmit={formOnSubmit}>
        {children}
      </form>
    );
  }

  return <>{children}</>;
};

const useStyles = createStyles((theme) => ({
  stickyButtonsModal: {
    padding: '24px 24px 0px',
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      padding: '16px 16px 0px',
    },
  },
  stickyButtonsGroup: {
    position: 'sticky',
    bottom: 0,
    background: 'white',
    padding: theme.spacing.lg,
    boxShadow: `0px -1px 2px 0px rgba(0, 0, 0, 0.10), 0px -1px 3px 0px
                rgba(0, 0, 0, 0.05)`,
    width: '100%',
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      padding: theme.spacing.md,
    },
  },
}));

type DefaultProps = CottageModalProps &
  BaseModalHeaderProps & {
    title: string;
    additionalInfo?: string | React.JSX.Element;
    bodyContainerProps?: StackProps;
    children?: React.ReactNode;
    hasStickyActionBar?: boolean;
    primaryAction?: React.ReactElement<
      ActionButtonProps | LinkButtonProps | TooltipButtonProps
    >;
    secondaryAction?: React.ReactElement<
      ActionButtonProps | LinkButtonProps | TooltipButtonProps
    >;
    subtitle?: ReactNode;
    tertiaryAction?: React.ReactElement<
      ActionButtonProps | LinkButtonProps | TooltipButtonProps
    >;
  };

type ModalWithFormProps = DefaultProps & {
  formOnSubmit: FormEventHandler<HTMLFormElement>;
  hasStickyActionBar: boolean;
  isForm: boolean;
  primaryAction: React.ReactElement<
    ActionButtonProps | LinkButtonProps | TooltipButtonProps
  >;
};

type ModalWithoutFormProps = DefaultProps & {
  formOnSubmit?: never;
  isForm?: never;
};

export type BaseModalProps = ModalWithFormProps | ModalWithoutFormProps;
/**
 * BaseModal is the standard modal format used in the app with an icon in the
 * upper left and buttons horizontally on the bottom.
 * CottageModal is a thin wrapper around Mantine's modal used for more custom
 * modal use cases.
 */
const BaseModal = ({
  children,
  formOnSubmit,
  hasStickyActionBar = false,
  iconColor,
  isForm,
  onClose,
  primaryAction,
  secondaryAction,
  tertiaryAction,
  subtitle,
  title,
  withCloseButton,
  additionalInfo,
  bodyContainerProps,
  ...props
}: BaseModalProps) => {
  const { classes: containerClasses } = useContainerStyles();
  const { classes } = useStyles();

  const actionButtons = hasStickyActionBar ? (
    <Group position="right" className={classes.stickyButtonsGroup}>
      <SizeContext.Provider value={{ size: DisplaySize.COMPACT }}>
        {tertiaryAction}
        {secondaryAction}
        {primaryAction}
      </SizeContext.Provider>
    </Group>
  ) : (
    <Stack spacing="xxs">
      <SizeContext.Provider value={{ size: DisplaySize.DEFAULT }}>
        {primaryAction}
        {secondaryAction}
        {tertiaryAction}
      </SizeContext.Provider>
    </Stack>
  );

  const hasActions = !!primaryAction || !!secondaryAction || !!tertiaryAction;

  let displayedInfo: React.JSX.Element | undefined = undefined;
  if (additionalInfo) {
    displayedInfo = (
      <Popover
        position="bottom"
        withArrow
        shadow="md"
        withinPortal
        zIndex={zIndex.MODAL_OVERLAY}
        width="400px"
      >
        <Popover.Target>
          <ActionIcon>
            <TbInfoCircle size="1.25rem" />
          </ActionIcon>
        </Popover.Target>
        <Popover.Dropdown>
          {typeof additionalInfo === 'string' ? (
            <Text>{additionalInfo}</Text>
          ) : (
            additionalInfo
          )}
        </Popover.Dropdown>
      </Popover>
    );
  }

  return (
    <CottageModal
      padding="xxl"
      scrollAreaComponent={ScrollArea.Autosize}
      onClose={onClose}
      // Reduces accessibility, but prevents the modal from opening with form
      // fields selected
      trapFocus={!isForm}
      styles={hasStickyActionBar ? { body: { padding: 0 } } : undefined}
      {...props}
    >
      <ModalContainer isForm={isForm} formOnSubmit={formOnSubmit}>
        <Stack
          className={`${containerClasses.gap} ${
            hasStickyActionBar && classes.stickyButtonsModal
          }`}
        >
          <BaseModalHeader
            onClose={onClose}
            withCloseButton={withCloseButton}
            iconColor={iconColor}
            {...props}
          />
          <Stack spacing="xxxs">
            <Group spacing="xxxs">
              <Text
                fz={22}
                fw="bold"
                sx={{
                  wordBreak: 'break-word',
                  overflowWrap: 'break-word',
                }}
              >
                {title}
              </Text>
              {additionalInfo && displayedInfo}
            </Group>
            {subtitle && (
              <Text
                variant="subtitle"
                sx={{
                  wordBreak: 'break-word',
                  overflowWrap: 'break-word',
                }}
              >
                {subtitle}
              </Text>
            )}
          </Stack>
          <Stack spacing="xxs" {...bodyContainerProps}>
            {children}
          </Stack>
          {!hasStickyActionBar && hasActions && actionButtons}
        </Stack>
        {hasStickyActionBar && hasActions && actionButtons}
      </ModalContainer>
    </CottageModal>
  );
};

export default BaseModal;
