import { Divider, Stack } from '@mantine/core';

import { Company, RedactedCompany } from '@/types/api/company';
import { ProposalCompany } from '@/types/api/proposals';

import {
  CompanyAttribute,
  hasExperienceInMunicipality,
} from './CompanyAttribute';

type CompanyAttributeProps = {
  company: ProposalCompany | Company | RedactedCompany;
  includeDivider?: boolean;
  limit?: number;
  projectMunicipality?: string;
};

/**
 * Displays company attributes in order: experienced municipality, completed
 * projects, and other static attributes.
 * @param limit - limit the number of displayed attributes
 * @param projectMunicipality - the municipality of the project. If company
 * has experience in that municipality, it will be displayed.
 */
export const CompanyAttributes = ({
  company,
  limit,
  projectMunicipality,
  includeDivider,
}: CompanyAttributeProps) => {
  const getDisplayedAttributes = () => {
    const attributes: JSX.Element[] = [];

    const hasExperience = hasExperienceInMunicipality(
      company?.company_derived_data_cache?.experienced_municipalities,
      projectMunicipality
    );

    if (company && hasExperience) {
      attributes.push(
        <CompanyAttribute
          key={`municipality-${company.id}`}
          attributeType="MUNICIPALITY_EXPERIENCE"
          companyType={company.company_type}
        />
      );
    }

    // TODO (COT-3651): Put this back
    // const projectCount = company?.company_derived_data_cache?.projects_completed;

    // if (!!projectCount && projectCount >= 5) {
    // displayedAttributes.push(
    //   <CompletedProjectsAttribute
    //     count={projectCount}
    //     companyType={company.company_type}
    //     key={`projectCount ${company.id}`}
    //   />
    // );
    // }

    if (company?.static_attributes && company?.static_attributes?.length > 0) {
      const staticAttributes = company.static_attributes
        .map((attribute) => {
          return (
            <CompanyAttribute
              key={`${attribute}-${company.id}`}
              attributeType={attribute}
              companyType={company.company_type}
            />
          );
        })
        .filter((e) => e !== undefined);

      attributes.push(...staticAttributes);
    }

    return attributes;
  };

  const displayedAttributes = getDisplayedAttributes();

  return (
    <>
      {displayedAttributes.length > 0 && (
        <>
          {includeDivider && <Divider />}
          <Stack spacing="sm">
            {displayedAttributes.slice(0, limit ?? displayedAttributes.length)}
          </Stack>
        </>
      )}
    </>
  );
};
