import { NotificationType } from '@prisma/client';
import useSWR from 'swr';

import { Notification } from '@/types/api/notifications';

import { createGetFetcher } from '../api/client/fetchers';
import { withQueryParams } from '../apiUtils';

const get = createGetFetcher('notifications');

type UseNotificationsParams = {
  notificationType?: NotificationType;
  projectId?: string;
  shouldCall?: boolean;
};

const useNotifications = ({
  notificationType,
  projectId,
  shouldCall = true,
}: UseNotificationsParams) => {
  const queryParams = {
    queryType: notificationType,
    projectId: projectId,
  };

  const { data, error, isLoading, mutate } = useSWR<Notification[], Error>(
    shouldCall && withQueryParams(`/api/notifications`, queryParams),
    get
  );

  return {
    notifications: data,
    error,
    loading: isLoading,
    reloadNotifications: mutate,
  };
};

export default useNotifications;
