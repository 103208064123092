import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react';

import { DefaultSupportModalOptions } from '../SupportModal';

type SupportModalContextType = {
  closeSupportModal: () => void;
  openSupportModal: (options?: DefaultSupportModalOptions) => void;
  supportModalOpen: boolean;
  options?: DefaultSupportModalOptions;
};

const SupportModalContext = createContext<SupportModalContextType | undefined>(
  undefined
);

export const SupportModalProvider = ({ children }: PropsWithChildren) => {
  const [supportModalOpen, setSupportModalOpen] = useState(false);
  const [options, setOptions] = useState<
    DefaultSupportModalOptions | undefined
  >();

  const openSupportModal = useCallback((opts?: DefaultSupportModalOptions) => {
    setOptions(opts);
    setSupportModalOpen(true);
  }, []);

  const closeSupportModal = useCallback(() => {
    setSupportModalOpen(false);
    setOptions(undefined);
  }, []);

  return (
    <SupportModalContext.Provider
      value={{
        openSupportModal,
        closeSupportModal,
        supportModalOpen,
        options,
      }}
    >
      {children}
    </SupportModalContext.Provider>
  );
};

export const useSupportModal = (): SupportModalContextType => {
  const context = useContext(SupportModalContext);
  if (context === undefined) {
    throw new Error(
      'useSupportModal must be used within a SupportModalProvider'
    );
  }

  return context;
};
